import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Label,
  UncontrolledTooltip,
  Table,
  Input,

} from 'reactstrap'
//Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb'
import moment from 'moment'
import {
  getUsersAv,
  getDays

} from 'store/settings/actions'

import { map } from 'lodash'
import AvailabilityModal from './AvailabilityModal'

class TeamAvailability extends Component {
  constructor(props) {
    super(props)
    this.node = React.createRef()
    this.state = {
      name: '',
      total: 0,
      pages: [],
      sectors: [],
      currentPage: 1,
      user: '',
      startDate: "",
      endDate: "",
      selectedUser: null,
      isModalOpen: false, 
    }

  }

  componentDidMount() {
    const { users, onGetUsersAv, days, onGetDays } = this.props
    if (users && !users.length) {
      onGetUsersAv(1, name)
    }
    if (!days.length) {
      onGetDays()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { users, onGetUsersAv, totalCount, page: currentPage } = this.props
    if (prevProps.page !== this.props.page || prevState.currentPage !== this.state.currentPage || prevState.startDate !== this.state.startDate || prevState.endDate !== this.state.endDate) {
      onGetUsersAv(this.state.currentPage, '')
    }

    if (prevProps.totalCount !== totalCount) {
      const totalPages = Math.ceil(totalCount / 10);
      const arrayPages = Array.from({ length: totalPages }, (_, i) => i + 1);
      this.setState({ total: totalCount, pages: arrayPages });
    }
    if(this.state.name !== prevState.name){
      onGetUsersAv(1, this.state.name)
    }
  }

  toggleModal = (user) => {
    this.setState((prevState) => ({
      selectedUser: user || null,
      isModalOpen: !prevState.isModalOpen,
    }));
  };
  


  render() {
    document.title = 'Solicitações Rejeitadas | Atendimento NUPI'
    const { users, totalCount } = this.props
    const totalPages = Math.ceil(totalCount / 10)
    const arrayPages = []
    for (let i = 1; i <= totalPages; i++) {
      arrayPages.push(i)
    }
    return (
      <React.Fragment>
        <div className='page-content'>
          <Container fluid>
            <Breadcrumbs title='Relatórios' breadcrumbItem='Solicitações rejeitadas' />
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg="12">
                        <Row>
                          <Col md="5" className="align-items-baseline">
                            <Label for="name" className="form-label">Nome</Label>
                            <div>
                              <Input type="tewxt" name="name" id="name" style={{
                                width: '100%',
                                padding: '8px',
                                boxSizing: 'border-box',
                                border: '1px solid #ced4da',
                                borderRadius: '5px',
                                fontSize: '14px'
                              }} onChange={e => this.setState({ name: e.target.value })} />
                            </div>
                          </Col>
                        </Row>
                        <Table responsive>
                        </Table>
                      </Col>
                    </Row>

                    <Table responsive>
                      <thead className='text-center border-bottom'>
                        <tr>
                          <th>Nome</th>
                          <th>Dias disponíveis</th>
                          <th>Email</th>
                          <th className="w-25">Editar Disponibilidade</th>
                        </tr>
                      </thead>
                       <tbody className="text-center">
                        {users.map((user) => (
                          <tr key={user.id} tabIndex="0">
                            <td className="px-0" aria-label={`Nome do solicitante ${user.name}`}>
                              {user.name}
                            </td>

                            <td className="px-0" aria-label={`Data de criação ${moment(user.createdAt).format('DD/MM/YYYY HH:mm')}`}>
                              {moment(user.createdAt).format('DD/MM/YYYY HH:mm')}
                            </td>
                            <td className="px-0" aria-label={`Email ${user.email}`}>
                              {user.email}
                            </td>
                            <td className="px-0 w-25" aria-label="Botões de ações">
                              <div className="d-flex gap-3 justify-content-center">
                                <Button
                                  color="link"
                                  className="text-success p-0"
                                  onClick={() => this.toggleModal(user)}
                                  id={`editAvailability${user.id}`}
                                >
                                  <i className="mdi mdi-pencil font-size-18"></i>
                                </Button>
                                <UncontrolledTooltip
                                  placement="top"
                                  target={`editAvailability${user.id}`}
                                >
                                  Editar disponibilidade
                                </UncontrolledTooltip>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <AvailabilityModal
                      isOpen={this.state.isModalOpen}
                      toggle={() => this.toggleModal()}
                      user={this.state.selectedUser}
                      days={this.props.days}
                    />
                    <div className='d-flex flex-row-reverse bd-highlight'>
                      <div className='p-2 bd-highlight'>
                        {this.props.totalCount > 10 ? (
                          <React.Fragment>
                            <button
                              onClick={() => {
                                this.setState({ currentPage: 1 });
                              }}
                              className='btn btn-primary mx-1 '
                              style={{ backgroundColor: 'transparent', border: 'none', color: this.state.currentPage === 1 ? 'lightgray' : '#556ee6' }}
                            >
                              <div style={{ fontSize: '200%' }}> {'<<'} </div>
                            </button>
                            <button
                              disabled={this.state.currentPage === 1}
                              onClick={() => {
                                this.setState({ currentPage: this.state.currentPage - 1 });
                              }}
                              className='btn btn-primary mx-1'
                              style={{ borderRadius: '50%', backgroundColor: 'transparent', border: 'none', color: this.state.currentPage === 1 ? 'lightgray' : '#556ee6' }}
                            >
                              <div style={{ fontSize: '200%' }}> {'<'}</div>
                            </button>
                            {(() => {
                              let filteredPages = [];
                              arrayPages.forEach(page => {
                                if (page - 1 === this.state.currentPage || page + 1 === this.state.currentPage || page === this.state.currentPage) {
                                  filteredPages.push(page);
                                }
                              });
                              return filteredPages.map(page => (
                                <button
                                  disabled={page === this.state.currentPage}
                                  key={page}
                                  onClick={() => {
                                    this.setState({ currentPage: page });
                                  }}
                                  className='btn btn-primary mx-1 rounded-circle'
                                >
                                  {page}
                                </button>
                              ));
                            })()}
                            <button
                              disabled={this.state.currentPage === this.state.pages.length}
                              onClick={() => {
                                this.setState({ currentPage: this.state.currentPage + 1 });
                              }}
                              className='btn btn-primary mx-1'
                              style={{ borderRadius: '50%', backgroundColor: 'transparent', border: 'none', color: this.state.currentPage === this.state.pages.length ? 'lightgray' : '#556ee6' }}
                            >
                              <div style={{ fontSize: '200%' }}> {'>'}</div>
                            </button>
                            <button
                              onClick={() => {
                                this.setState({ currentPage: this.state.pages.length });
                              }}
                              className='btn btn-primary mx-1 '
                              style={{ backgroundColor: 'transparent', border: 'none', border: 'none', color: this.state.currentPage === this.state.pages.length ? 'lightgray' : '#556ee6' }}
                            >
                              <div style={{ fontSize: '200%' }}> {'>>'} </div>
                            </button>
                          </React.Fragment>
                        ) : ''}
                      </div>
                    </div>
                  
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          
        </div >
      </React.Fragment >
    )
  }
}

TeamAvailability.propTypes = {
  errorMessage: PropTypes.any,
  users: PropTypes.array,
  days: PropTypes.array,
  onGetUsersAv: PropTypes.func,
  onGetDays: PropTypes.func,
}

const mapStateToProps = (state) => ({
  errorMessage: state.settings.error,
  users: state.settings.users,
  currentPage: state.settings.page,
  totalCount: state.settings.totalCount,
  pageCount: state.settings.pageCount,
  page: state.settings.page,
  days: state.settings.days,
  loading: state.settings.loading
})


const mapDispatchToProps = dispatch => ({
  onGetUsersAv: (page = 1, name = '') => dispatch(getUsersAv(page, name)),
  onGetDays: () => dispatch(getDays())

})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeamAvailability)
)
