import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Table,
  Form,
} from "reactstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { CustomSelect } from "../Requests/NewRequestTabs/components/MutiSelect";
import { dayConvertion } from "constants/days";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  fetchAvailability,
  saveAvailability,
  getDays,
} from "store/actions"; // Atualize os caminhos conforme necessário
import availability from "./availability";

class AvailabilityModal extends Component {
  constructor(props) {
    super(props);
    this.scheduleTemplate = {
      weekDayId: "",
      startTime: "",
      endTime: "",
    };

    this.state = {
      availabilityList: [], // Lista de disponibilidades
    };
  }

  async componentDidUpdate(prevProps) {
    const { user, fetchAvailability } = this.props;
    if (user && user.id !== prevProps.user?.id) {
      fetchAvailability(user.id);
    }
    
    if (
      this.props.availability &&
      this.props.availability !== prevProps.availability
    ) {
      this.setState({ availabilityList: this.props.availability });
    }
  }



  handleAddAvailability = (values, resetForm) => {
    const newAvailability = {
      weekDayId: values.weekDayId,
      startTime: values.startTime,
      endTime: values.endTime,
    };

    this.setState((prevState) => ({
      availabilityList: [...(prevState.availabilityList || []), newAvailability],
    }));

    resetForm(); // Reseta os campos do formulário
  };

  handleRemoveAvailability = (index) => {
    this.setState((prevState) => ({
      availabilityList: prevState.availabilityList.filter(
        (_, i) => i !== index
      ),
    }));
  };

  handleSave = async () => {
    const { user, saveAvailability, toggle } = this.props;
    const { availabilityList } = this.state;

    if (user) {
      const data = {
        userId: user.id,
        availability: availabilityList,
      }
      await saveAvailability(data);
      toggle();
    }
  };

  render() {
    const { isOpen, toggle, user, days, availability } = this.props;
    const { availabilityList } = this.state;

    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Editar Disponibilidade</ModalHeader>
        <ModalBody>
          <p>Disponibilidade para {user ? user.name : "usuário"}.</p>
        </ModalBody>
        <Formik
          initialValues={{ weekDayId: "", startTime: "", endTime: "" }}
          validationSchema={Yup.object({
            weekDayId: Yup.string().required("Selecione um dia da semana"),
            startTime: Yup.string()
              .matches(/(\d){2}:(\d){2}/, 'Siga o padrão "00:00"')
              .required("Informe a hora de início"),
            endTime: Yup.string()
              .matches(/(\d){2}:(\d){2}/, 'Siga o padrão "00:00"')
              .required("Informe a hora de término")
              .test(
                "endTime",
                "A hora de término deve ser maior que a hora de início",
                function (value) {
                  return moment(value, "HH:mm").isAfter(
                    moment(this.parent.startTime, "HH:mm")
                  );
                }
              ),
          })}
          onSubmit={(values, { resetForm }) =>
            this.handleAddAvailability(values, resetForm)
          }
        >
          {({ handleSubmit }) => (
            <Col>
              <Form className="form-horizontal" onSubmit={handleSubmit}>
                <Card outline color="primary" className="border border-primary mb-3">
                  <CardBody>
                    <Row className="mb-3">
                      <Col lg="6">
                        <Label for="day">Dia da semana</Label>
                        <Field
                          name="weekDayId"
                          component={CustomSelect}
                          options={days.map((day) => ({ value: day.id, label: day.day }))}

                          placeholder="Selecione uma opção"
                          className="mb-2"
                        />
                        <ErrorMessage
                          name="day"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                      <Col lg="3">
                        <Label for="startTime">Início</Label>
                        <Field
                          type="time"
                          id="startTime"
                          name="startTime"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="startTime"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                      <Col lg="3">
                        <Label for="endTime">Término</Label>
                        <Field
                          type="time"
                          id="endTime"
                          name="endTime"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="endTime"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                    </Row>
                    <Button type="submit" color="primary" className="btn-sm">
                      Adicionar Disponibilidade
                    </Button>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          )}
        </Formik>
        <Table responsive>
          <thead className="text-center border-bottom">
            <tr>
              <th>Dia da semana</th>
              <th>Início</th>
              <th>Fim</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody className="text-center">
          {(this.state.availabilityList || []).map((item, index) => (
              <tr key={index}>
                <td>{dayConvertion[item.weekDayId]}</td>
                <td>{item.startTime}</td>
                <td>{item.endTime}</td>
                <td>
                  <Button color="danger" size="sm" onClick={() => this.handleRemoveAvailability(index)}>
                    Remover
                  </Button>
                </td>
              </tr>
            ))}

          </tbody>
        </Table>
        <ModalFooter>
          <Button color="primary" onClick={this.handleSave}>
            Salvar
          </Button>
          <Button color="secondary" onClick={toggle}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

AvailabilityModal.propTypes = {
  fetchAvailability: PropTypes.func.isRequired,
  saveAvailability: PropTypes.func.isRequired,
  days: PropTypes.array.isRequired,
  availability: PropTypes.array,
  user: PropTypes.object,
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  days: state.settings.days,
  availability: state.settings.availability,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAvailability: (userId) => dispatch(fetchAvailability(userId)),
  saveAvailability: (userId, availability) =>
    dispatch(saveAvailability(userId, availability)),
  getDays: () => dispatch(getDays()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AvailabilityModal)
);
